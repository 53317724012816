<template>
  <tr>
    <td class="text-center">{{ unSubmittedFileRow.file_name }}</td>
    <td class="text-center" v-if="!unSubmittedFileRow.submitted">{{ this.$store.state.user.name }}</td>
    <td class="text-center" v-else>{{ unSubmittedFileRow.submitted }}</td>
    <td class="text-center">{{ formattedDate(unSubmittedFileRow.created_at) }}</td>
    <td class="text-center">{{ formattedDate(unSubmittedFileRow.updated_at) }}</td>
    <td class="text-center" v-if="unSubmittedFileRow.submitted">
      <v-btn @click="downloadFile(unSubmittedFileRow.file_name)" elevation="2" color="success">
        Download
      </v-btn>
    </td>
    <td class="text-center" v-else>
      <v-btn @click="processFile(unSubmittedFileRow.file_name)"
             elevation="2" color="primary" :loading="loadingProcess">
        Process
      </v-btn>
    </td>
  </tr>
</template>

<script>
import ccrsApi from "@/lib/ccrs-api";
import dateFormatter from "@/lib/dateFormatter";

export default {
  name: "UnSubmittedFileRow",
  emits: ['updateComplete', 'downloadComplete'],
  props: ['unSubmittedFileRow'],
  data: () => ({
    loadingProcess: false,
    loadingDownload: false,
    unSubmittedFiles: [],
    processResponse: [],
    downloadResponse: [],
  }),
  methods: {
    async processFile(fileName) {
      this.loadingProcess = true;
      let query = await ccrsApi.get('/v2/labNewFiles/submit/' + this.$store.state.user.name + '/' + fileName);
      this.processResponse = query.data;
      this.loadingProcess = false;
      this.$emit("updateComplete", "someValue");
    },
    async downloadFile(fileName) {
      this.loadingDownload = true;
      let fileType = '';
      if (fileName.includes('labtest')) {
        fileType = 'labResults';
      } else {
        fileType = 'labProducts';
      }

      let query = await ccrsApi.download('/v2/' + fileType + '/download/' + fileName);
      let content = new Blob([query.data], {type: `application/csv`});
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(content);
      link.download = `${fileName}`;
      link.click();
      await ccrsApi.get('/v2/labNewFiles/delete/' + fileName);
      this.loadingDownload = false;
      this.$emit("downloadComplete", "someValue");
    },
    setAccessFile(fileName) {
      this.accessFile = fileName;
    },
    formattedDate(dateString) {
      return dateFormatter.formatDate(dateString);
    }
  }
}
</script>

<style scoped>

</style>